import '../styles/header.css'
import { Link, useLocation } from 'react-router-dom'
import React, { useEffect, useState } from 'react';
import { motion } from 'framer-motion';
import { useMediaQuery } from 'react-responsive';

export default function Header() {

  const location = useLocation();

  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const isMobileView = useMediaQuery({ query: '(max-width: 7768px)' })

  
  return (
    <>
    <motion.div initial={{ opacity: 0, y: -50 }} animate={{ opacity: 1, y: 0 }} transition={{ duration: 0.5, delay: 3 }} >
    <nav className={`absolute w-full mx-auto ${ isMenuOpen ? 'toggled' : 'pr-3 lg:pr-0' } ${location.pathname == '/' && ''}`}>
    <div className="containerx max-w-screen-2xl flex flex-wrap items-center justify-between mx-auto md:py-8 pt-5 pb-8 px-3">
    <div className="flex items-center mr-4">
      <Link to="/" className="flex items-center space-x-4">
        <img src='/logo.png' className='lg:h-12 h-10'/>
      </Link>
    </div>

    <div className='nav-buttons'>
        <div><a href="#contact" className="btn-main">Contact</a></div>
    </div>

    </div>
    </nav>
    </motion.div>
    </>
  )
}
